  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    border-bottom: #000 solid 1px;
    margin-bottom: 2rem;
  }
  
  .title {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
  }
  .sub-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
  }

  .form {
    width: 100%;
    margin-top: 0.5rem;
  }
  .form > * + * {
    margin-top: 1.5rem;
  }
  
  .button {
    width: 100%;
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    padding: 0.75rem;
    color: rgb(255, 255, 255);
    background-color: rgb(37, 99, 235);
  }
  .button:hover {
    background-color: rgb(59, 130, 246);
  }
  .button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 calc(4px) rgba(59, 130, 246, 0.5), 0 0 #0000;
    transition-property: border-color, box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .button:disabled:hover {
    background-color: rgb(37, 99, 235);
  }
  
  .text {
    color: rgb(107, 114, 128);
    text-align: center;
    margin: 0.5rem 0 0;
  }
  
  .link {
    color: rgb(37, 99, 235);
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .link:hover {
    color: rgb(59, 130, 246);
    text-decoration-line: underline;
    text-underline-offset: 1px;
  }
  
  .verification-text {
    margin-top: 3rem;
    text-align: center;
  }
  
  .error-text {
    margin-top: 1rem;
    text-align: center;
    color: rgb(239, 68, 68);
  }

  .input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .resultList {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }
  .resultListItem {
    display: inline-flex;
  }
  .resultListItem span {
    padding: .8rem;
    color: white;
    font-size: 130%;
  }

  .responseList {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0;
  }
  .responseListItem {
    display: inline-flex;
    border-radius: 20px;
  }
  .responseListItem span {
    border-radius: 1rem;
    color: white;
    padding: .5rem;
  }
  .Yes {
    background-color: green;
  }
  .Abstain {
    background-color: grey;
  }
  .No {
    background-color: red;
  }
  
  @media (min-width: 640px) {
    .input-group {
      flex-direction: row;
    }
    .input-email-wrapper {
      max-width: 28rem;
    }
    .card {
      padding-left: 2rem;
      padding-right: 2rem;
      background-color: rgb(255, 255, 255);
      border-width: 1px;
      border-radius: 0.75rem;
      border-color: rgba(229, 231, 235, 0.5);
      box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
  }