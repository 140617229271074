  .title {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
  }
  .sub-title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 400;
  }
  
  .welcome-text {
    margin-top: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  
  .info-text {
    margin-top: 1rem;
    color: rgb(107, 114, 128);
  }
  .info-text > code {
    color: rgb(59, 130, 246);
    background-color: rgb(219, 234, 254);
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media (min-width: 1024px) {
    .container {
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
    }
  }
  
  .info > h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .info > p {
    margin-top: 0.25rem;
    color: rgb(107, 114, 128);
    line-height: 1.25;
  }
  
  @media (min-width: 640px) {
    .info {
      min-width: 320px;
    }
    .info > h2 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  
  .card {
    width: 100%;
    max-width: 768px;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    border-width: 1px;
    border-radius: 0.375rem;
    border-color: rgba(229, 231, 235, 0.5);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
  
  .form-fields {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .form-fields > * + * {
    margin-top: 1.5rem;
  }
  @media (min-width: 768px) {
    .form-fields {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  
  .form-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: rgb(249, 250, 251);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media (min-width: 768px) {
    .form-footer {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
 
  @media (min-width: 640px) {
    .input-email-wrapper {
      max-width: 28rem;
    }
  }
  
  .button {
    color: rgb(255, 255, 255);
    background-color: rgb(55, 65, 81);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.375rem;
    /* transition */
    transition-property: color, background-color, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .button:hover {
    background-color: rgb(75, 85, 99);
  }
  .button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 calc(4px) rgb(55 65 81 / 0.2), 0 0 #0000;
  }
  .button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .button:disabled:hover {
    background-color: rgb(55, 65, 81);
  }
  